.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.allowedContainerWrapper {
  width: fit-content;
  min-width: 300px;
}

.allowedDomainWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 5px 30px 5px 0px;
  align-items: center;
  border: 1px solid gray;
  border-radius: 5px;
}

.deleteBtn {
  color: red !important;
}

.manageBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.newDomainWrapper {
  padding: 5px;
}

.newDomainInput {
  font-size: inherit;
  border: none;
}

.newDomainInput:focus {
  border: none;
  outline: none !important;
}
