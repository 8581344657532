.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  min-width: 400px;
  margin: 0 auto;
  margin-top: 40px;
}
