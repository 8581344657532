.modalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid rgb(218, 226, 237);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0.25rem 0.75rem;
    padding: 1.5rem;
}

.modalHeader {
    margin-top: 0;
    margin-bottom: 1.5rem;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .closeButton {
        position: relative;
        top: -0.75rem;
        right: -0.75rem;
        border-radius: 0.5rem;
    }
}

.modalBody {}
