.text {
    background-color: #EEE;
    display: flex;
    align-items: center;
    width: 100%;
    color: black;
    padding: 0.75rem 2.5rem 0.75rem 1.25rem;
    box-sizing: border-box;
    margin-top: 0rem !important;
    font-size: 1.5rem !important;
}

.subtitle {
    font-size: 0.9rem;
    margin-left: 25px;
}
