.templateVariable:hover {
    cursor: pointer;
    color: #0288d1;
    font-weight: bold;

    &:active {
        color: #0399ea;
    }
}

.templateVariableActive {
    font-weight: bold !important;
}

.templateFieldGroup {
    border-left: 2px solid;
    border-radius: 1rem;
    padding: 2rem 0rem 0 1rem;
    position: relative;
}

.templateFieldGroupTitle {
    position: absolute;
    border-radius: 0.5rem;
    left: -2px;
    top: 0;
    padding-left: 2rem;
    padding-right: 2rem;
    color: white;
}

.templateTab {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
}

.templateTab :global(.MuiBadge-badge){
    top: -2px;
    right: -5px;
}

.templateFieldGroup.defaultFieldGroup {
     /*extra styles for default group*/
}
