.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

tr.row {
  vertical-align: baseline;
}

.table a.userLink, .table a.userLink:visited {
  color: #0e6ac0;
  text-decoration: none;
}

.table a.stageLink, .table a.stageLink:visited {
  color: #0a4f8f;
  text-decoration: none;
}

/*ID column*/
.table th:nth-child(1) {
  width: 5%;
  max-width: 5% !important;
}
