.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-main-dark);
  padding: 35px 60px;
}
.items {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.item {
  color: var(--color-white);
  text-align: center;
  text-decoration: unset;
  outline: unset;
  /*font-family: Archivo;*/
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.item:hover {
  color: var(--color-gray);
  cursor: pointer;
}
.item.logout {
  color: #0288d1;
}
.active {
  text-decoration: underline;
}
