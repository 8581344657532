.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
}

.table {
  padding: 20px;
}

tr.row {
  vertical-align: baseline;
}

.code {
  white-space: pre;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid var(--color-gray);
  background-color: var(--color-gray-2);
}

.search {
  margin: 15px 20px 10px 20px;
  display: flex;
  width: full;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchInput {
  display: flex;
  align-items: center;
}

.searchButton {
  margin-left: 18px;
  height: 56px;
  border: 1px solid black
}

.search > * {
  height: 100%;
}

.search_cpp {
  min-width: 200px;
}

.requests_style span {
  display: inline;
}

.dialog_window {
  max-width: 80%!important;
}

.dialog_window * {
  white-space: break-spaces;
}

.copy_content {
  margin-top: 10px;
  margin-bottom: 20px;
  width: fit-content;
  margin-left: auto;
}

.requestTypeGroup:global(.MuiListSubheader-root) {
  color: #bbb;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 2rem;
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}

