.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.search {
  margin: 15px 20px 10px 20px;
  display: flex;
  width: full;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.searchInput {
  display: flex;
  align-items: center;
}

.searchButton {
  margin-left: 18px;
  height: 56px;
  border: 1px solid black
}

.search > * {
  height: 100%;
}

.search_cpp {
    min-width: 200px;
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding: 10px;
}

tr.row {
  vertical-align: baseline;
}

.table a.userLink, .table a.userLink:visited {
  color: #0e6ac0;
  text-decoration: none;
}
