.container {
  margin: 0 auto;
  margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  min-width: 400px;
}
